
<template>
  <v-container>
    <v-layout align-center justify-center>
      <v-row>
        <v-col align="center" v-show="mostrarBotonCerrar"> </v-col>
      </v-row>
      <v-row v-show="mostrarSoftphone">
        <v-col align="center">
          <v-row justify="center">
            <v-col cols="6">
              <v-text-field
                v-show="verTeclado"
                class="centered-input"
                style="font-size: 1.6rem"
                v-model="numeroDestino"
                :append-outer-icon="'mdi-backspace'"
                type="text"
                @click:append-outer="vaciar()"
              />
            </v-col>
          </v-row>
          <numericpad :size="2" v-show="verTeclado" :onInput="onInput" />
          <v-row
            v-show="llamadaIniciadaVentada || llamadaEntranteVentanaTime"
            outlined
            color="transparent"
          >
            <v-col>
              <div>
                <p style="font-size: 2.6rem">{{ numeroDeLlamada }}</p>
              </div>
              <cronometro />

              <v-row>
                <v-col class="col-md-8 offset-md-2">
                  <v-row>
                    <v-col>
                      <mute-boton />
                    </v-col>
                    <v-col>
                      <pausar-boton />
                    </v-col>
                    <v-col>
                      <dtfm-boton-dialog></dtfm-boton-dialog>
                    </v-col>
                  </v-row>

                  <v-row>
                    <colgar-boton color="error" v-show="vercolgar" />
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-md-4">
            <llamar-boton
              v-show="verllamar"
              v-bind:itemEntrada="numeroDestino"
            />
            <colgar-boton v-show="verBoton && vercolgar" />
            <video ref="probavideo" width="1px" height="10px" autoPlay />
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-snackbar
          v-model="snackbar"
          :bottom="true"
          :right="true"
          :timeout="3000"
          :color="snack_color"
        >
          {{ snack_text }}
        </v-snackbar>
      </v-row>
    </v-layout>
  </v-container>
</template>
<script>
import PhoneCommander from "./phone/phoneCommander";
import numericpad from "./phone/numeric-keypad.vue";
//import store from "./store";
import llamarBoton from "./phone/llamarBoton";
import colgarBoton from "./phone/colgarBoton";
import cronometro from "./phone/cronometro";
import muteBoton from "./phone/muteBoton";
import pausarBoton from "./phone/pausarBoton";
import dtfmBotonDialog from "./phone/dtfmBotonDialog";
import DtfmBotonDialog from "./phone/dtfmBotonDialog.vue";

export default {
  components: {
    numericpad,
    llamarBoton,
    colgarBoton,
    cronometro,
    muteBoton,
    pausarBoton,
    dtfmBotonDialog,
    DtfmBotonDialog,
  },
  data: () => ({
    maxLength: 15,
    snackbar: false,
    snack_color: "",
    snack_text: "",
    nombreLLamadaEntrante: "",
    mostrarSoftphone: false,
    llamadaIniciada: false,
    numeroDestino: "",
    myInstance: "",
    estadoCerrar: false,
    mostrarBotonCerrar: false,
    mostrarFormulario: true,
    llamadaIniciadaVentada: false,
    llamadaEntranteVentana: false,
    llamadaEntranteVentanaTime: false,
    enLlamada: false,
    vTeclado: false,
    verTeclado: true,
    verBoton: true,
    vercolgar: false,
    verllamar: true,
    numeroDeLlamada: "",
    verDtmf: false,
  }),
  props: {
    modoDebug: { default: "none" },
  },
  methods: {
    arranqueSoftphone() {
      let datos = this.$store.getters.getSipuser;
      if (datos) {
        let extension = datos.sipuser[0].user + "@" + datos.sipuser[0].domain;
        let passExtension = datos.sipuser[0].password;
        this.extension = extension;
        this.registrar(extension, passExtension);
      } else {
        localStorage.removeItem("UserStorage");
        this.$router.push("/").catch((err) => {});
      }
    },

    onInput(key) {
      if (this.enLlamada == false) {
        this.numeroDestino = (this.numeroDestino + key).slice(
          0,
          this.maxLength
        );
      }
    },

    go(msg, color) {
      this.snack_text = msg;
      this.snack_color = color;
      this.snackbar = true;
    },
    registrar(user, pass) {
      if (!this.$store.getters.getEstasRegistrado) {
        const debug = this.modoDebug;
        const videoTagRef = this.$refs.probavideo;
        var sipIdentity = "sip:" + user;
        var secretPass = pass;
        var userauth = sipIdentity.split(":")[1].split("@")[0];
        var dispName = userauth.split("-")[1];
        var sipRegistrar = "sip:" + sipIdentity.split("@")[1];
        const sipPluginConfig = {
          sipIdentity: sipIdentity,
          displayName: dispName,
          sipRegistrar: sipRegistrar,
          username: userauth,
          secret: secretPass,
        };
        this.myInstance = new PhoneCommander(
          sipPluginConfig,
          debug,
          videoTagRef
        );
        this.myInstance.connect(); 
        this.$store.dispatch("setinstanciaPhone", this.myInstance);
        this.$store.dispatch("setregistro", this.myInstance.registro);
        this.$store.dispatch("setEstasRegistrado", true);
        this.$notification.show("Softphone iniciado.", {}, {});
      } else {
        this.vercolgar = this.$store.getters.getverColgar;
        this.mostrarSoftphone = true;
      }
    },

    vaciar() {
      if (!this.llamadaEntranteVentanaTime && !this.llamadaIniciadaVentada) {
        this.numeroDestino = this.numeroDestino.slice(0, -1);
      }
    },

    checkConn() {
        // reconexión en base á existencia da sesión no gateway.
        // soluciona as desconexiós largas creando unha nova sesión.
        setInterval(() => {
          // si non existe o id de session indicado na url
          // o gateway devolverá un erro, así verificamos que 
          // a sesión ainda existe no gateway
          const checkSession = async () => {
            let session_id;
let instanciaVuex
instanciaVuex=this.$store.getters.getinstanciaPhone;

            if (typeof instanciaVuex.getSessionId !== "undefined") {
                session_id = instanciaVuex.getSessionId();
            }
            //console.log(session_id);

            if (session_id) {
                let config = {
                  headers: {
                    "Content-Type": "application/json",
                  },
                };

                let randomString = Math.random().toString(36).slice(2);

                try {
                    return await this.$axios.post(
                        "https://gateway.norvoz.es/janus/" + session_id, 
                        {
                            "janus": "keepalive",
                            "transaction": randomString,
                        },
                        config
                    ); 
                } catch (error) {
                    this.$store.dispatch("setEstasRegistrado", false);
                }

                if (typeof instanciaVuex.getSessionId !== "undefined") {
                    session_id = instanciaVuex.getSessionId();
                }
            }
          };

          const processCheckSessionResult = async() => { 
            const resposta = await checkSession();
            if (resposta) {
                //console.log(resposta.data);
                if (resposta.data.error) {
                    console.log("Sesión caducada.");
                    this.$store.dispatch("setEstasRegistrado", false);
                    this.$store.dispatch("setregistro", false); // hai un watch mirando desto e lanza o rexistro de novo
                } else {
                    this.$store.dispatch("setEstasRegistrado", true);
                    this.$store.dispatch("setregistro", true);
                }
            }
          };
          
          processCheckSessionResult(); 
        }, 10000)
    },
  },
  computed: {
    VLlamadaEstado() {
      return this.$store.getters.getllamadaEstado;
    },
    Vregistered() {
      return this.$store.getters.getregistro;
    },
    VincommingCall() {
      return this.$store.getters.getnombreLLamadaEntrante;
    },
    Vcolgar() {
      return this.$store.getters.getverColgar;
    },
    VllamadaEntranteVentanaTime() {
      return this.$store.getters.getllamadaEntranteVentanaTime;
    },
  },

  watch: {
    VLlamadaEstado(value) {
      if (value == "accepted") {
        if (this.llamadaEntranteVentana != true) {
          this.llamadaIniciadaVentada = true;
          this.enLlamada = true;
          this.verBoton = false;
          this.numeroDeLlamada = this.numeroDestino;
          this.numeroDestino = "";
          this.verTeclado = false;
          this.verDtmf = true;
        }
        this.$store.dispatch("setstateCrono", "started");
        this.$store.dispatch("setllamadaEstado", "intermedio");
        this.$store.dispatch("setRecargaListado", true);
      }
      if (value == "hangup") {
        this.$store.dispatch("settecladoDtmf", false);

        this.$store.dispatch("setiniciarBotonera", false);
        this.vercolgar = false;
        this.$store.dispatch("setstateCrono", "reset");
        this.$store.dispatch("setllamadaEntranteVentanaTime", false);
        this.$store.dispatch("setiniciarBotonera", false);
        this.numeroDestino = "";
        this.numeroDeLlamada = "";

        this.$store.dispatch("setnombreLLamadaEntrante", "");

        this.$store.dispatch("setstateCrono", "reset");
        this.llamadaEntranteVentana = false;
        this.llamadaEntranteVentanaTime = false;
        this.llamadaIniciadaVentada = false;
        this.enLlamada = false;
        this.verBoton = true;
        this.verTeclado = true;

        this.$store.dispatch("setllamadaEstado", "intermedio");
        this.$store.dispatch("setRecargaListado", true);
        this.verDtmf = false;
      }
      if (value == "transferenciaNotificada") {
        this.$store.dispatch("setiniciarBotonera", false);
        this.numeroDestino = "";
        this.vercolgar = false;
        this.$store.dispatch("setstateCrono", "reset");
        this.llamadaEntranteVentana = false;
        this.llamadaEntranteVentanaTime = false;
        this.llamadaIniciadaVentada = false;
        this.enLlamada = false;
        this.verBoton = true;
        this.verTeclado = true;
        this.$store.dispatch("setRecargaListado", true);
      }
    },
    Vcolgar(value) {
      if (value) {
        this.vercolgar = true;
        this.verllamar = false;
      } else {
        this.vercolgar = false;
        this.verllamar = true;
      }
    },
    Vregistered(value) {
      var phoneRetorno = this.$store.getters.getinstanciaPhone;
      this.estadoCerrar = this.$store.getters.getestadoCerrar;
      if (this.$store.getters.getregistro == true) {
        console.log("Registrado.");
        this.go("Registrado", "success");
        this.mostrarBotonCerrar = true;
        this.mostrarFormulario = false;
        this.mostrarSoftphone = true;
      } else {
        console.log("Intentando registrar...");
        this.arranqueSoftphone();
        /*
        if (this.estadoCerrar == false) {
          if (phoneRetorno.registro == true) {
            this.mostrarFormulario = true;
            this.mostrarSoftphone = false;
          }
          if (this.$store.getters.getregistro == false) {
            this.go("No Registra, vuelva a intentarlo", "error");
            this.mostrarFormulario = true;
            this.mostrarSoftphone = false;
          }
        } else {
          if (this.$store.getters.getregistro == false) {
            this.estadoCerrar = false;
            this.mostrarFormulario = true;
            this.mostrarSoftphone = false;
          }
          this.mostrarFormulario = true;
          this.mostrarSoftphone = false;
        }
        */
      }
    },

    VincommingCall(value) {
      if (value != "") {
        this.verBoton = false;
        if (value.includes(":") && value.includes("@")) {
          value = value.split(":")[1].split("@")[0];
        }
        this.$store.dispatch("setnombreLLamadaEntrante", value);
        this.nombreLLamadaEntrante = value;
        this.llamadaEntranteVentana = true;
        this.numeroDeLlamada=value
        if (this.vTeclado) {
          this.enLlamada = false;
          this.verTeclado = true;
        } else {
          this.enLlamada = true;
          this.verTeclado = false;
        }
      }
    },
    VllamadaEntranteVentanaTime(value) {
      this.numeroDestino = "";
      this.vercolgar = true;
      this.verBoton = false;
      this.llamadaEntranteVentanaTime = true;
      this.$store.dispatch("setiniciarBotonera", true);
      console.log(this.$store.getters.getnombreLLamadaEntrante);
      this.numeroDestino = this.$store.getters.getnombreLLamadaEntrante;
      this.llamadaEntranteVentanaTime = value;
    },
  },
  mounted() {
    this.arranqueSoftphone();
    this.checkConn();
  },
};
</script>
<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>

